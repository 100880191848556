<template>
  <a-modal :title="$t('events.create-channel-modal.create-channel')" v-model:visible="newChannelModalVisible"
    :footer="null">
    <a-form layout="vertical" name="newChannel">
      <a-form-item name="channelName" :label="$t('events.create-channel-modal.channel-name') + '*'">
        <a-input v-model:value="createChannel.name" @change="validateCahnnelName" />
        <p style="color: red" v-show="!this.validateChannel">
          Lütfen özel karaklerler girmeyiniz.
        </p>
      </a-form-item>

      <a-form-item name="channelType" label="Kanal tipi">
        <a-radio-group v-model:value="channelType" name="radioGroup">
          <div>
            <a-radio default-checked value="STANDARD"></a-radio>
            <span>
              <strong>Standard</strong> -
              {{
                  $t('events.create-channel-modal.standard')
              }}
            </span>
          </div>
          <div>
            <a-radio value="BASIC"></a-radio>
            <span>
              <strong>Basic</strong> -
              {{
                  $t('events.create-channel-modal.basic')
              }}
            </span>
          </div>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="eventChannel" label="Gecikme seçeneği">
        <a-radio-group v-model:value="channelLatency" name="radioGroup">
          <div>
            <a-radio default-checked value="NORMAL"></a-radio>
            <span>
              <strong>Normal</strong>-
              {{
                  $t('events.create-channel-modal.normal')
              }}
            </span>
          </div>
          <div>
            <a-radio value="LOW"></a-radio>
            <span>
              <strong>Low</strong> -
              {{
                  $t('events.create-channel-modal.low')
              }}
            </span>
          </div>
        </a-radio-group>
      </a-form-item>

      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="newChannel">
          {{ $t('events.create-channel-modal.save-channel') }}
        </button>

      </a-form-item>
    </a-form>
  </a-modal>

  <!--  platform-->
  <a-modal :title="$t('events.create-platform-modal.create-platform')" v-model:visible="newPlatformModalVisible"
    :footer="null">
    <a-form layout="vertical" name="newPlatform">
      <a-form-item name="domainName" :label="$t('events.create-platform-modal.domain') + '*'">
        <a-input v-model:value="createPlatform.domainName" />
      </a-form-item>
      <a-form-item name="eventPlatform" :label="$t('events.create-platform-modal.e-commerce-provider') + '*'"
        has-feedback>
        <a-select class="w-full" v-model:value="createPlatform.ecommerceProviderId"
          :placeholder="$t('events.create-platform-modal.e-commerce-provider') + '*'">
          <a-select-option v-for="provider in providers" :key="provider.id" :value="provider.id">{{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>


      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-username') + '*'">
        <a-input v-model:value="createPlatform.username" />
      </a-form-item>

      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-user-password') + '*'">
        <a-input v-model:value="createPlatform.password" />
      </a-form-item>


      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="newPlatform">
          {{ $t('events.create-platform-modal.save-platform') }}
        </button>

      </a-form-item>
    </a-form>
  </a-modal>

  <!--  edit platform-->
  <a-modal :title="$t('events.create-platform-modal.edit-platform')" v-model:visible="showEditPlatformForm"
    :footer="null">
    <a-form layout="vertical" name="newPlatform">
      <a-form-item name="domainName" :label="$t('events.create-platform-modal.domain') + '*'">
        <a-input v-model:value="editPlatformForm.domainName" />
      </a-form-item>
      <a-form-item name="eventPlatform" :label="$t('events.create-platform-modal.e-commerce-provider') + '*'"
        has-feedback>
        <a-select class="w-full" v-model:value="editPlatformForm.ecommerceProviderId"
          :placeholder="$t('events.create-platform-modal.e-commerce-provider') + '*'">
          <a-select-option v-for="provider in providers" :key="provider.id" :value="provider.id">{{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>


      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-username') + '*'">
        <a-input v-model:value="editPlatformForm.username" />
      </a-form-item>

      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-user-password') + '*'">
        <a-input v-model:value="editPlatformForm.password" />
      </a-form-item>


      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="editPlatform">
          {{ $t('events.create-platform-modal.save-platform') }}
        </button>

      </a-form-item>
    </a-form>
  </a-modal>

  <a-drawer v-model:visible="eventDrawer" @close="eventDrawer = false" :footer-style="{ textAlign: 'right' }"
    class="event-drawer drawer-form" :title="$t('events.create-event-drawer.create-event')" placement="right"
    width="400px">
    <a-form ref="modalFormRef" layout="vertical" name="eventForm">

      <a-upload
       list-type="picture-card"
        name="event-image" 
        :show-upload-list="false" 
        class="avatar-uploader"
        :before-upload="preventUpload">
        <img v-if="createEvent.image !== null" :src="createEvent.image.thumbUrl" alt="event-image"
          class="text-center" />
        <div v-else>
          <!--          <a-icon type="plus" />-->
          <div class="ant-upload-text">
            {{ $t('upload-photo') }}
          </div>
        </div>
      </a-upload>


      <a-form-item name="eventName" :label="$t('events.create-event-drawer.event-title') + '*'">
        <a-input v-model:value="createEvent.title" />
        <a-alert v-if="errors.title.status" type="error" v-bind:message="errors.title.message" banner />
      </a-form-item>

      <a-form-item name="eventDescription" :label="$t('events.create-event-drawer.event-description') + '*'">
        <a-textarea v-model:value="createEvent.description" placeholder="..." :auto-size="{ minRows: 3, maxRows: 5 }" />
        <a-alert v-if="errors.description.status" type="error" v-bind:message="errors.description.message" banner />
      </a-form-item>
      <a-form-item name="eventPlatform" :label="$t('events.create-event-drawer.live-stream-platform') + '*'"
        has-feedback>
        <div class="flex justify-between">
          <a-select class="w-full" :placeholder="$t('events.create-event-drawer.live-stream-platform')"
            @change="handlePlatform" :key="renderKey">
            <a-select-option key="0" value="0" @click="showNewPlatformModal" disabled style="cursor: pointer">
              <span style="color: black">
                <font-awesome-icon icon="plus" class=" text-base"></font-awesome-icon>
                {{ $t('events.create-event-drawer.add-platform') }}
              </span>
            </a-select-option>
            <a-select-option v-for="platform in this.platforms" :key="platform.id" :value="platform.id">
              <div class="flex justify-between">
                <span>
                  {{ platform.domain_name }}
                </span>
                <div>
                  <span style="color: black" @click="showEditPlatform(platform)" class="mr-2">
                    <font-awesome-icon icon="pen" class=" text-base" />
                  </span>
                  <span style="color: black" @click="deletePlatform(platform)">
                    <font-awesome-icon icon="trash" class=" text-base" />
                  </span>
                </div>
              </div>

            </a-select-option>
          </a-select>
          <div>
            <a-tooltip>
              <template #title>
                <br>
                {{
                    $t("event-question.platform")
                }}
              </template>
              <font-awesome-icon icon="question" class="text-gray-300 mx-2 " />
            </a-tooltip>
          </div>
        </div>
        <a-alert v-if="errors.platformId.status" type="error" v-bind:message="errors.platformId.message" banner />
      </a-form-item>

      <a-form-item name="eventChannel" :label="$t('events.create-event-drawer.live-stream-channel')" has-feedback>
        <div class="flex justify-between">

          <a-select class="w-full" :placeholder="$t('events.create-event-drawer.live-stream-channel')"
            @change="handleChannel">
            <a-select-option key="0" value="0" @click="showNewChannelModal" disabled style="cursor: pointer">
              <span style="color: black">
                <font-awesome-icon icon="plus" class=" text-base"></font-awesome-icon>
                {{ $t('events.create-event-drawer.add-channel') }}
              </span>
            </a-select-option>
            <a-select-option v-for="channel in channels" :key="channel.id" :value="channel.id">
              <div class="flex justify-between">

                <span>{{ channel.name }}</span>
                <div>

                  <span style="color: black" @click="deleteChannel(channel)">
                    <font-awesome-icon icon="trash" class=" text-base" />
                  </span>
                </div>
              </div>
            </a-select-option>
          </a-select>
          <div>
            <a-tooltip>
              <template #title>
                <br>
                {{
                    $t("event-question.channel")
                }}
              </template>
              <font-awesome-icon icon="question" class="text-gray-300 mx-2 " />
            </a-tooltip>
          </div>

        </div>
        <a-alert v-if="errors.channelId.status" type="error" v-bind:message="errors.channelId.message" banner />
      </a-form-item>

      <a-form-item name="eventCode" :label="$t('events.create-event-drawer.event-code')">
        <div class="flex justify-between">

          <div class="w-full">
            <a-input v-model:value="createEvent.code" />
            <a-alert v-if="errors.code.status" type="error" v-bind:message="errors.code.message" banner />
          </div>
          <div>
            <a-tooltip>
              <template #title>
                <br>
                {{
                    $t("event-question.event-code")
                }}
              </template>
              <font-awesome-icon icon="question" class="text-gray-300 mx-2 " />
            </a-tooltip>
          </div>
        </div>

      </a-form-item>

      <a-form-item name="eventChannel" label="Etkileşim">
        <a-checkbox default-checked @change="onChangeInteraction">
          Diğer yayınlardan kullanıcılar yayınıma gelebilir ve bu yayında diğer yayınlar gösterilebilir.
        </a-checkbox>
      </a-form-item>

      <div class="w-full flex items-center justify-between">
        <a-form-item class="w-7/12" name="eventDate" :label="$t('events.create-event-drawer.live-stream-date') + '*'">
          <a-date-picker class="w-full" value-format="YYYY-MM-DD" @change="handleDate" />
          <a-alert v-if="errors.date.status" type="error" v-bind:message="errors.date.message" banner />
        </a-form-item>
        <a-form-item class="w-4/12 float-right" name="eventTime"
          :label="$t('events.create-event-drawer.live-stream-time') + '*'">
          <a-time-picker class="w-full" value-format="HH:mm:ss" @change="handleTime" />
          <a-alert v-if="errors.date.status" type="error" v-bind:message="errors.date.message" banner />
        </a-form-item>
      </div>

    </a-form>
    <button
      class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
      v-on:click="saveEvent">
      {{ $t('events.create-event-drawer.save-event') }}
    </button>
    <!--      <template #footer>-->
    <!--        <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button>-->
    <!--        <a-button type="primary" @click="onClose">Submit</a-button>-->
    <!--      </template>-->

  </a-drawer>
</template>

<script>

import Providers from "@/api/user/providers";

export default {

  data() {
    return {
      providers: [],
     
      channelType: "STANDARD",
      channelLatency: "LOW",
      newChannelModalVisible: false,
      newPlatformModalVisible: false,
      createChannel: {
        name: ""
      },
      createPlatform: {

      },
      channels: [],
      platforms: [],
      createEvent: {
        title: null,
        description: null,
        platformId: null,
        channelId: null,
        date: null,
        time: null,
        interaction: true,
        code: null,
        image: null
      },
      errors: {
        title: {
          status: false,
          message: ""
        },
        description: {
          status: false,
          message: ""
        },
        platformId: {
          status: false,
          message: ""
        },
        channelId: {
          status: false,
          message: ""
        },
        date: {
          status: false,
          message: ""
        },
        code: {
          status: false,
          message: ""
        }
      },
      editPlatformForm: {

      },
      editPlatformLoading: true,
      showEditPlatformForm: false,
      renderKey: 0,
      validateChannel: true

    }
  },
  methods: {
    deleteChannel(channel) {
      this.$swal.showLoading();
      this.$appAxios.get('/deleteChannel?id=' + channel.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {

        this.$appAxios.get('/listChannel', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.channels = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.create-channel-modal.channel-deleted')
        })
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })
    },
    validateCahnnelName() {
      this.validateChannel = /^[A-Za-z][A-Za-z0-9]*$/.test(this.createChannel.name);
    },
    deletePlatform(platform) {
      this.$swal.showLoading();
      this.$appAxios.get('/deletePlatform?id=' + platform.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {

        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {
          this.platforms = [];
          this.platforms = response.data.data
          this.reRender();
          this.$swal({
            icon: "success",
            title: 'Platform Başarıyla Silindi'
          })
        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })
    },
    reRender() {
      this.renderKey++;
    },
    showEditPlatform(platform) {
      this.showEditPlatformForm = true
      this.$swal.showLoading()
      Providers.getProviders().then((response) => {
        this.providers = response.data.data.providers
        this.$swal.close()
      })
      this.editPlatformLoading = true;
      this.$appAxios.get('/getPlatform?id=' + platform.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((result) => {
        this.editPlatformForm.domainName = result.data.data.domain_name
        this.editPlatformForm.ecommerceProviderId = result.data.data.ecommerce_provider_id
        this.editPlatformForm.id = result.data.data.id
        this.editPlatformForm.site_url = result.data.data.ws_info.site_url
        this.editPlatformForm.username = result.data.data.ws_info.username
        this.editPlatformForm.password = result.data.data.ws_info.password
        this.editPlatformLoading = false;
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })

    },
    onChangeInteraction(e) {
      this.interaction = e.target.checked;
    },
    preventUpload(file) {
       const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        file.thumbUrl = e.target.result;
        this.createEvent.image = file
      }
      const isLt8M = file.size / 1024 / 1024 < 8;
      if (!isLt8M) {
        this.$swal({
          icon: 'error',
          title: this.$t('events.create-event-drawer.image-file-size-error'),
        }).then(() => {
          this.createEvent.image = null;
        })

      }
      return false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
  
   
    showNewPlatformModal() {

      this.$swal.showLoading()
      Providers.getProviders().then((response) => {
        this.providers = response.data.data.providers
        this.newPlatformModalVisible = !this.newPlatformModalVisible;
        this.$swal.close()
      })

    },
    showNewChannelModal() {
      this.newChannelModalVisible = !this.newChannelModalVisible;
    },
    newPlatform() {

      this.$swal.showLoading()

      this.$appAxios.post('/addPlatformToUser', {
        domainName: this.createPlatform.domainName,
        ecommerceProviderId: this.createPlatform.ecommerceProviderId,
        wsInfo: {
          site_url: this.createPlatform.domainName,
          username: this.createPlatform.username,
          password: this.createPlatform.password
        }
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.platforms = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.create-platform-modal.platform-created')
        })
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.newPlatformModalVisible = false;
    },
    newChannel() {
      this.$swal.showLoading()

      this.$appAxios.post('/createChannel', {
        channelName: this.createChannel.name,
        type: this.channelType,
        latencyMode: this.channelLatency
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/listChannel', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.channels = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.create-channel-modal.channel-created')
        })
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.newChannelModalVisible = false;
    },
    handlePlatform(value) {
      this.createEvent.platformId = value
    },
    handleChannel(value) {
      this.createEvent.channelId = value
    },
    handleDate(date) {
      this.createEvent.date = date
    },
    handleTime(time) {
      this.createEvent.time = time
    },
    editPlatform() {
      this.$swal.showLoading()

      this.$appAxios.post('/addPlatformToUser', {
        id: this.editPlatformForm.id,
        domainName: this.editPlatformForm.domainName,
        ecommerceProviderId: this.editPlatformForm.ecommerceProviderId,
        wsInfo: {
          site_url: this.editPlatformForm.domainName,
          username: this.editPlatformForm.username,
          password: this.editPlatformForm.password
        }
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {
          this.platforms = [];
          this.platforms = response.data.data
          this.reRender();
          this.$swal({
            icon: "success",
            title: this.$t('events.create-platform-modal.platform-created')
          })
        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )

      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.showEditPlatformForm = false;
    },
    saveEvent() {

      let data = { ...this.createEvent }

      if (data.time !== null && data.date !== null) {
        data.date = data.date + " " + data.time
        delete data.time
      }

      let formData = new FormData()

      for (let property in data) {

        if (data[property] == null)
          continue

        if (Array.isArray(data[property])) {

          for (let image in data[property]) {
            formData.append(property + '[]', data[property][image].originFileObj)
          }
          continue
        }

        formData.append(property, data[property])

      }

      this.$swal.showLoading()

      this.$appAxios.post('/createEvent', formData, {
        headers: {
          'Authorization': [`Bearer ${localStorage.getItem("token")}`],
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {

        this.$store.dispatch('setFutureEvents')
        this.selectedEvent = response.data.data.event

        this.$store.commit('setEventDrawer', false)

        this.$swal({
          icon: "success",
          title: this.$t('events.create-event-drawer.event-created')
        })
      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          case 422:

            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            // eslint-disable-next-line no-case-declarations
            let obj = JSON.parse(error.response.data.error.description);

            for (const [key, value] of Object.entries(obj)) {

              this.errors[key].status = true
              this.errors[key].message = value

            }
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
    getChannels() {
      this.$appAxios.get('/listChannel', {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((response) => {

        this.channels = response.data.data
      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
    getPlatforms() {
      this.$appAxios.get('/getUserPlatforms', {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((response) => {

        this.platforms = response.data.data

        this.$swal.close()

      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
  },
  computed: {
    eventDrawer: {
      get() {
        return this.$store.state.eventDrawer
      },
      set(val) {
        this.$store.commit('setEventDrawer', val)
      }
    },
    selectedEvent: {
      get() {
        return this.$store.state.selectedEvent
      },
      set(val) {
        this.$store.commit('setSelectedEvent', val)
      }
    }
  },
  watch: {
    eventDrawer(newVal) {
      if (newVal === true) {
        this.getChannels()
        this.getPlatforms()
      }
    }
  },
  mounted() {
    if (this.$store.state.layout !== 'streamer') {
      this.getChannels()
      this.getPlatforms()
    }
  }

}
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>