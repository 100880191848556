import {appAxios} from "@/utils/appAxios";

const Event = {

    getPastEvents(params){
        return appAxios.get('/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },
            params: {
                type: 'past',
                page: params.page,
                limit: params.limit
            }
        })
    },
    getPastEventsForStreamer(params){
        return appAxios.get('/streamer/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("streamerToken")}`]
            },
            params: {
                type: 'past',
                page: params.page,
                limit: params.limit
            }
        })
    },

    getFutureEvents(params){
        return appAxios.get('/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },
            params: {
                type: 'future',
                page: params.page,
                limit: params.limit
            }
        })
    },
    getFutureEventsForStreamer(params){
        return appAxios.get('/streamer/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("streamerToken")}`]
            },
            params: {
                type: 'future',
                page: params.page,
                limit: params.limit
            }
        })
    },

    getLiveEvents(params){

        return appAxios.get('/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },
            params: {
                type:'live',
                page: params.page,
                limit: params.limit
            }
        })
    },

    getLiveEventsForStreamer(params){

        return appAxios.get('/streamer/getEvents', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("streamerToken")}`]
            },
            params: {
                type:'live',
                page: params.page,
                limit: params.limit
            }
        })
    },

}

export default Event