  <template>
  <a-modal :title="$t('events.create-channel-modal.create-channel')" v-model:visible="newChannelModalVisible"
    :footer="null">
    <a-form layout="vertical" name="newChannel">
      <a-form-item name="channelName" :label="$t('events.create-channel-modal.channel-name') + '*'">
        <a-input v-model:value="createChannel.name" @change="validateCahnnelName" />
        <p style="color: red" v-show="!this.validateChannel">
          Lütfen özel karaklerler girmeyiniz.
        </p>
      </a-form-item>

      <a-form-item name="channelType" label="Kanal tipi">
        <a-radio-group v-model:value="channelType" name="radioGroup">
          <div>
            <a-radio default-checked value="STANDARD"></a-radio>
            <span>
              <strong>Standard</strong> -
              {{
                  $t('events.create-channel-modal.standard')
              }}
            </span>
          </div>
          <div>
            <a-radio value="BASIC"></a-radio>
            <span>
              <strong>Basic</strong> -
              {{
                  $t('events.create-channel-modal.basic')
              }}
            </span>
          </div>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="eventChannel" label="Gecikme seçeneği">
        <a-radio-group v-model:value="channelLatency" name="radioGroup">
          <div>
            <a-radio default-checked value="NORMAL"></a-radio>
            <span>
              <strong>Normal</strong>-
              {{
                  $t('events.create-channel-modal.normal')
              }}
            </span>
          </div>
          <div>
            <a-radio value="LOW"></a-radio>
            <span>
              <strong>Low</strong> -
              {{
                  $t('events.create-channel-modal.low')
              }}
            </span>
          </div>
        </a-radio-group>
      </a-form-item>

      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="newChannel">
          {{ $t('events.create-channel-modal.save-channel') }}
        </button>

      </a-form-item>
    </a-form>
  </a-modal>

  <!--  platform-->
  <a-modal :title="$t('events.create-platform-modal.create-platform')" v-model:visible="newPlatformModalVisible"
    :footer="null">
    <a-form layout="vertical" name="newPlatform">
      <a-form-item name="domainName" :label="$t('events.create-platform-modal.domain') + '*'">
        <a-input v-model:value="createPlatform.domainName" />
      </a-form-item>
      <a-form-item name="eventPlatform" :label="$t('events.create-platform-modal.e-commerce-provider') + '*'"
        has-feedback>
        <a-select class="w-full" v-model:value="createPlatform.ecommerceProviderId"
          :placeholder="$t('events.create-platform-modal.e-commerce-provider') + '*'">
          <a-select-option v-for="provider in this.providers" :value="provider.id" :key="provider.id">{{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>


      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-username') + '*'">
        <a-input v-model:value="createPlatform.username" />
      </a-form-item>

      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-user-password') + '*'">
        <a-input v-model:value="createPlatform.password" />
      </a-form-item>


      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="newPlatform">
          Kaydet
        </button>

      </a-form-item>
    </a-form>
  </a-modal>

  <!--  edit platform-->
  <a-modal :title="$t('events.create-platform-modal.edit-platform')" v-model:visible="showEditPlatformForm"
    :footer="null">
    <a-form layout="vertical" name="newPlatform">
      <a-form-item name="domainName" :label="$t('events.create-platform-modal.domain') + '*'">
        <a-input v-model:value="editPlatformForm.domainName" />
      </a-form-item>
      <a-form-item name="eventPlatform" :label="$t('events.create-platform-modal.e-commerce-provider') + '*'"
        has-feedback>
        <a-select class="w-full" v-model:value="editPlatformForm.ecommerceProviderId"
          :placeholder="$t('events.create-platform-modal.e-commerce-provider') + '*'">
          <a-select-option v-for="provider in providers" :key="provider.id" :value="provider.id">{{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>


      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-username') + '*'">
        <a-input v-model:value="editPlatformForm.username" />
      </a-form-item>

      <a-form-item name="Username" :label="$t('events.create-platform-modal.platform-user-password') + '*'">
        <a-input v-model:value="editPlatformForm.password" />
      </a-form-item>


      <a-form-item>
        <button
          class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
          v-on:click="editPlatform">
          {{ $t('events.create-platform-modal.save-platform') }}
        </button>

      </a-form-item>
    </a-form>
  </a-modal>


  <a-drawer v-model:visible="this.$store.state.editEventDrawer" @close="closeEditDrawer"
    :footer-style="{ textAlign: 'right' }" class="event-drawer drawer-form"
    :title="$t('events.edit-event-drawer.edit-event')" placement="right" width="465px">
    <a-form ref="modalFormRef" layout="vertical" name="eventForm">

      <a-upload list-type="picture-card" :file-list="editEvent.images" @preview="handlePreview" @change="handleChange"
        :before-upload="preventUpload">
        <div>
          <!--          <a-icon type="plus" />-->
          <div class="ant-upload-text">
            {{ $t('upload-photo') }}

          </div>
        </div>
      </a-upload>

      <a-form-item name="eventName" :label="$t('events.edit-event-drawer.event-title') + '*'">
        <a-input v-model:value="editEvent.title" />
        <a-alert v-if="errors.title.status" type="error" v-bind:message="errors.title.message" banner />
      </a-form-item>

      <a-form-item name="eventDescription" :label="$t('events.edit-event-drawer.event-description') + '*'">
        <a-textarea v-model:value="editEvent.description" placeholder="..." :auto-size="{ minRows: 3, maxRows: 5 }" />
        <a-alert v-if="errors.description.status" type="error" v-bind:message="errors.description.message" banner />
      </a-form-item>

      <a-form-item name="eventPlatform" :label="$t('events.edit-event-drawer.live-stream-platform') + '*'" has-feedback>
        <a-select v-model:value="defaultPlatformName" class="w-full" :key="renderKey"
          :placeholder="$t('events.edit-event-drawer.live-stream-platform')">
          <a-select-option key="0" value="0" @click="showNewPlatformModal" disabled style="cursor: pointer">
            <span style="color: black">
              <font-awesome-icon icon="plus" class=" text-base"></font-awesome-icon>
              {{ $t('events.edit-event-drawer.add-platform') }}
            </span>
          </a-select-option>
          <a-select-option v-for="platform in platforms" :key="platform.id" :value="platform.id">
            <div class="flex justify-between">
              <span>
                {{ platform.domain_name }}
              </span>
              <div>
                <span style="color: black" @click="showEditPlatform(platform)" class="mr-2">
                  <font-awesome-icon icon="pen" class=" text-base" />
                </span>
                <span style="color: black" @click="deletePlatform(platform)">
                  <font-awesome-icon icon="trash" class=" text-base" />
                </span>
              </div>
            </div>
          </a-select-option>
        </a-select>
        <a-alert v-if="errors.platformId.status" type="error" v-bind:message="errors.platformId.message" banner />
      </a-form-item>

      <a-form-item name="eventChannel" :label="$t('events.edit-event-drawer.live-stream-channel')" has-feedback>
        <a-select v-model:value="defaultChannelName" class="w-full"
          :placeholder="$t('events.edit-event-drawer.live-stream-channel')">
          <a-select-option key="0" value="0" @click="showNewChannelModal" disabled style="cursor: pointer">
            <span style="color: black">
              <font-awesome-icon icon="plus" class=" text-base" />{{ $t('events.edit-event-drawer.add-channel') }}
            </span>
          </a-select-option>
          <a-select-option v-for="channel in channels" :key="channel.id" :value="channel.id">
            <div class="flex justify-between">
              <span>{{ channel.name }}</span>
              <div>

                <span style="color: black" @click="deleteChannel(channel)">
                  <font-awesome-icon icon="trash" class=" text-base" />
                </span>
              </div>
            </div>
          </a-select-option>
        </a-select>
        <a-alert v-if="errors.channelId.status" type="error" v-bind:message="errors.channelId.message" banner />
      </a-form-item>

      <a-form-item name="eventCode" :label="$t('events.edit-event-drawer.event-code')">
        <a-input v-model:value="editEvent.code" />
        <a-alert v-if="errors.code.status" type="error" v-bind:message="errors.code.message" banner />
      </a-form-item>

      <div class="w-full flex items-center justify-between">
        <a-form-item class="w-7/12" name="eventDate" :label="$t('events.edit-event-drawer.live-stream-date') + '*'">
          <a-date-picker :value="this.editEvent.date" class="w-full" value-format="YYYY-MM-DD" @change="handleDate" />
          <a-alert v-if="errors.date.status" type="error" v-bind:message="errors.date.message" banner />
        </a-form-item>
        <a-form-item class="w-4/12 float-right" name="eventTime"
          :label="$t('events.edit-event-drawer.live-stream-time') + '*'">
          <a-time-picker :value="this.editEvent.time" class="w-full" value-format="HH:mm:ss" @change="handleTime" />
          <a-alert v-if="errors.date.status" type="error" v-bind:message="errors.date.message" banner />
        </a-form-item>
      </div>

    </a-form>
    <button
      class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
      v-on:click="saveEvent">
      {{ $t('events.edit-event-drawer.save-event') }}
    </button>
    <!--      <template #footer>-->
    <!--        <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button>-->
    <!--        <a-button type="primary" @click="onClose">Submit</a-button>-->
    <!--      </template>-->

  </a-drawer>
</template>

  <script>

import Providers from "@/api/user/providers";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {

  data() {
    return {
      previewVisible: false,
      previewImage: '',
      newChannelModalVisible: false,
      editChannelModalVisible: false,
      newPlatformModalVisible: false,
      createChannel: {
        name: ""
      },
      createPlatform: {},
      channels: [],
      platforms: [],
      defaultChannel: null,
      defaultPlatform: null,
      editEvent: {
        title: null,
        description: null,
        platformId: null,
        channelId: null,
        date: null,
        time: null,
        code: null,
        images: []
      },
      errors: {
        title: {
          status: false,
          message: ""
        },
        description: {
          status: false,
          message: ""
        },
        platformId: {
          status: false,
          message: ""
        },
        channelId: {
          status: false,
          message: ""
        },
        date: {
          status: false,
          message: ""
        },
        code: {
          status: false,
          message: ""
        }
      },
      editChannelForm: {
        id: 0,
        name: ''
      },
      editPlatformForm: {

      },
      editPlatformLoading: true,
      showEditPlatformForm: false,
      renderKey: 0,
      channelType: "STANDARD",
      channelLatency: "LOW",
      validateChannel: true

    }
  },
  mounted() {
    if (this.$store.state.editedEvent != null) {
      this.$store.state.editedEvent.image_urls = [];
    }
    this.setEditedEventData()
  },
  methods: {
    deleteChannel(channel) {
      this.$swal.showLoading();
      this.$appAxios.get('/deleteChannel?id=' + channel.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {

        this.$appAxios.get('/listChannel', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.channels = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.create-channel-modal.channel-deleted')
        })
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })
    },
    deletePlatform(platform) {
      this.$swal.showLoading();
      this.$appAxios.get('/deletePlatform?id=' + platform.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {

        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {
          this.platforms = response.data.data
          this.reRender();
          this.$swal({
            icon: "success",
            title: 'Platform Başarıyla Silindi'
          })
        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })
    },
    showEditChannel(channel) {
      this.editChannelForm.id = channel.id
      this.editChannelForm.name = '';
      this.editChannelModalVisible = true;
    },
    closeEditDrawer() {
      this.editEvent = {
        title: null,
        description: null,
        platformId: null,
        channelId: null,
        date: null,
        time: null,
        images: []
      }
      this.editEventDrawer = false
    },
    preventUpload(file) {
      const isLt8M = file.size / 1024 / 1024 < 8;
      if (!isLt8M) {
        this.$swal({
          icon: 'error',
          title: this.$t('events.create-event-drawer.image-file-size-error'),
        }).then(() => {
          this.createEvent.image = null;
        })

      }
      return false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(fileList) {
      this.editEvent.images = fileList.fileList
      if (fileList.fileList.length > 1) {
        this.editEvent.images.splice(0, 1)
      }

    },
    validateCahnnelName() {
      this.validateChannel = /^[A-Za-z][A-Za-z0-9]*$/.test(this.createChannel.name);
    },
    showNewPlatformModal() {
      this.newPlatformModalVisible = !this.newPlatformModalVisible;
    },
    showNewChannelModal() {
      this.newChannelModalVisible = !this.newChannelModalVisible;
    },
    newPlatform() {
      this.$swal.showLoading()

      this.$appAxios.post('/addPlatformToUser', {
        domainName: this.createPlatform.domainName,
        ecommerceProviderId: this.createPlatform.ecommerceProviderId,
        wsInfo: {
          site_url: this.createPlatform.domainName,
          username: this.createPlatform.username,
          password: this.createPlatform.password
        }
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.platforms = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.edit-event-drawer.process-success')
        })
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.newPlatformModalVisible = false;
    },
    showEditPlatform(platform) {
      this.showEditPlatformForm = true
      this.$swal.showLoading()
      Providers.getProviders().then((response) => {
        this.providers = response.data.data.providers
        this.$swal.close()
      })
      this.editPlatformLoading = true;
      this.$appAxios.get('/getPlatform?id=' + platform.id, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((result) => {
        this.editPlatformForm.domainName = result.data.data.domain_name
        this.editPlatformForm.ecommerceProviderId = result.data.data.ecommerce_provider_id
        this.editPlatformForm.id = result.data.data.id
        this.editPlatformForm.site_url = result.data.data.ws_info.site_url
        this.editPlatformForm.username = result.data.data.ws_info.username
        this.editPlatformForm.password = result.data.data.ws_info.password
        this.editPlatformLoading = false;
      }).catch((error) => {
        this.$swal({
          icon: 'error',
          text: error.response.data.error.description
        })
      })

    },
    editPlatform() {
      this.$swal.showLoading()

      this.$appAxios.post('/addPlatformToUser', {
        id: this.editPlatformForm.id,
        domainName: this.editPlatformForm.domainName,
        ecommerceProviderId: this.editPlatformForm.ecommerceProviderId,
        wsInfo: {
          site_url: this.editPlatformForm.domainName,
          username: this.editPlatformForm.username,
          password: this.editPlatformForm.password
        }
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/getUserPlatforms', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {
          this.platforms = response.data.data
          this.reRender();
          this.$swal({
            icon: "success",
            title: this.$t('events.create-platform-modal.platform-created')
          })
        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )

      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.showEditPlatformForm = false;
    },
    reRender() {
      this.renderKey++;
    },
    newChannel() {
      this.$swal.showLoading()

      this.$appAxios.post('/createChannel', {
        channelName: this.createChannel.name,
        type: this.channelType,
        latencyMode: this.channelLatency
      }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/listChannel', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.channels = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.create-channel-modal.channel-created')
        })
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.newChannelModalVisible = false;
    },
    editChannel() {
      this.$swal.showLoading()

      this.$appAxios.post('/createChannel', { channelName: this.createChannel.name }, {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then(() => {
        this.$appAxios.get('/listChannel', {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then((response) => {

          this.channels = response.data.data

        }).catch(error => {
          switch (error.response.status) {

            case 500:
              this.$swal({
                icon: 'error',
                title: this.$t('errors.system-error.title'),
                html: this.$t('errors.system-error.text')
              })
              break

            default:

              this.$swal({
                icon: 'error',
                text: error.response.data.error.description
              })
              break
          }
        }
        )
        this.$swal({
          icon: "success",
          title: this.$t('events.edit-event-drawer.process-success')
        })
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break
          case 422:
            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            break
          default:
            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      });
      this.newChannelModalVisible = false;
    },
    // handlePlatform(value) {
    //
    //   this.editEvent.platformId = value
    // },
    // handleChannel(value) {
    //   this.editEvent.channelId = value
    // },
    handleDate(date) {
      this.editEvent.date = date
    },
    handleTime(time) {
      this.editEvent.time = time
    },
    saveEvent() {

      let data = { ...this.editEvent }

      if (data.time !== null && data.date !== null) {
        data.date = data.date + " " + data.time
        delete data.time
      }

      let images = data.images
      delete data.images
      data.oldUrls = []
      data.images = []

      images.forEach(image => {

        if (image.thumbUrl) {
          data.images.push(image)
        } else {
          data.oldUrls.push(image.url)
        }

      })

      let formData = new FormData()

      for (let property in data) {

        if (data[property] == null)
          continue

        if (property === 'images') {

          for (let image in data[property]) {
            formData.append(property + '[]', data[property][image].originFileObj)
          }
          continue
        }

        if (property === 'oldUrls') {
          for (let url in data[property]) {
            formData.append(property + '[]', data[property][url])
          }
          continue
        }

        formData.append(property, data[property])
      }

      this.$swal.showLoading()

      this.$appAxios.post('/updateEvent/' + this.$store.state.editedEvent.id, formData, {
        headers: {
          'Authorization': [`Bearer ${localStorage.getItem("token")}`],
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {

        this.$store.dispatch('setFutureEvents')
        this.$store.commit('setEventDrawer', false)

        this.$swal({
          icon: "success",
          title: this.$t('events.edit-event-drawer.process-success')
        })
      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          case 422:

            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            // eslint-disable-next-line no-case-declarations
            let obj = JSON.parse(error.response.data.error.description);

            for (const [key, value] of Object.entries(obj)) {

              this.errors[key].status = true
              this.errors[key].message = value

            }
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
    async getChannels() {
      await this.$appAxios.get('/listChannel', {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((response) => {

        this.channels = response.data.data

      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )

    },
    async getPlatforms() {
      await Providers.getProviders().then((response) => {
        this.providers = response.data.data.providers
      })
      await this.$appAxios.get('/getUserPlatforms', {
        headers: {
          Authorization: [`Bearer ${localStorage.getItem("token")}`]
        }
      }).then((response) => {
        this.platforms = response.data.data
      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )

    },
    async setEditedEventData() {
      if (this.$store.state.editedEvent == null) {
        return false
      }
      this.$swal.showLoading()

      await this.getChannels()
      await this.getPlatforms()

      this.editEvent.title = this.$store.state.editedEvent.title
      this.editEvent.description = this.$store.state.editedEvent.description
      if (this.$store.state.editedEvent.channel_id !== null) {
        this.defaultChannel = await this.channels.find(channel => channel.id === this.$store.state.editedEvent.channel_id)
        this.editEvent.channelId = this.defaultChannel.id
      }
      if (this.$store.state.editedEvent.platform_id !== null) {
        this.defaultPlatform = await this.platforms.find(platform => platform.id === this.$store.state.editedEvent.platform_id)
        this.editEvent.platformId = this.defaultPlatform.id
      }
      // if(this.$store.state.editedEvent.platform_id !== 0 ){
      //   this.defaultPlatform = await this.platforms.find(platform => platform.id === this.$store.state.editedEvent.platform_id)
      //   this.editEvent.platformId = this.defaultPlatform.id
      // }

      this.editEvent.code = this.$store.state.editedEvent.code

      let splitDate = this.$store.state.editedEvent.date.split(" ")
      this.editEvent.date = splitDate[0]
      this.editEvent.time = splitDate[1]

      if (this.$store.state.editedEvent.image_urls) {
        this.$store.state.editedEvent.image_urls.forEach((imageUrl, index) => {
          this.editEvent.images.push({
            uid: index,
            url: imageUrl,
          })
        })
      }
      this.$swal.close()
    }
  },
  computed: {
    editEventDrawer: {
      get() {
        return this.$store.state.editEventDrawer
      },
      set(val) {
        this.$store.commit('setEditEventDrawer', val)
      }
    },
    defaultChannelName: {
      get() {
        return this.defaultChannel ? this.defaultChannel.name : null
      },
      set(val) {
        this.editEvent.channelId = val
      }
    },
    defaultPlatformName: {
      get() {
        return this.defaultPlatform ? this.defaultPlatform.domain_name : null
      },
      set(val) {
        this.editEvent.platformId = val
      }
    },
  },
  watch: {
    editEventDrawer(newVal) {
      if (newVal === true) {
        this.setEditedEventData()
      }
    }
  },


}
</script>

  <style>
  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  </style>