<template>
  <div class="card  md:w-6/12 sm:w-full" :class="this.$store.state.layout !=='streamer' ? 'lg:w-3/12':'lg:w-4/12'" >
    <div class="h-full bg-gray-200 rounded-md">
      <div class="card-top px-4 py-4 mb-2 flex justify-between items-center">
        <div class="card-title flex items-center">
                      <span class="anticon bg-blue-100 w-10 h-10 rounded-3xl">
                          <font-awesome-icon icon="percentage" class="text-blue-500 text-base h-10"/>
                      </span>
          <span class="text-lg font-medium ml-3">{{$t('events.campaign-block.campaigns')}}</span>
        </div>
        <div class="card-link">
          <a-button
              v-if="this.$store.state.layout !=='streamer'"
              @click="$store.commit('setCampaignDrawer',true)"
              type="primary"
              class="bg-blue-500 font-normal text-xs text-white hover:text-white px-5 py-1 h-8 rounded-md"
          >
            + {{ $t('events.campaign-block.new-campaign') }}
          </a-button>
        </div>
      </div>
      <div class="card-center card-tabs">
        <a-tabs v-model:activeKey="activeCampaignTab">
          <a-tab-pane v-if="this.$store.state.layout !== 'streamer'"  key="1" v-bind:tab="$t('events.campaign-block.all-campaigns')+'('+ $store.state.allCampaignsPaging.total +')'">
            <div class="px-4 py-2 pb-6 tab-content">
              <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
                <div class="w-1/12"></div>
                <div class="md:w-3/12 sm:w-5/12 text-xs px-1 font-medium">{{$t('events.campaign-block.campaign-name')}}</div>
                <div class="w-3/12 sm:hidden md:block text-xs px-1 font-medium">{{$t('events.campaign-block.campaign-detail')}}</div>
                <div class="w-2/12 sm:hidden md:block text-xs px-1 font-medium">{{$t('events.campaign-block.campaign-id')}}</div>
                <div class="md:w-2/12 sm:w-5/12 text-xs px-1 font-medium">{{$t('events.campaign-block.campaign-code')}}</div>
              </div>
              <div class="overflow-y-auto" style="height: 67vh;" >

              <div class="w-full flex flex-col flex-wrap tab-content__data">

                <div
                    class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                    v-for="campaign in $store.state.allCampaigns" :key="campaign.id" :value="campaign.id">
                  <div class="w-full flex flex-row justify-between items-center">
                    <div class="w-1/12">
                      <label class="text-xs text-gray-700 flex items-center cursor-pointer">
                        <a-checkbox v-if="this.$store.state.layout !=='streamer'" :value="campaign.id" @change="setSelectedCampaigns" :checked="$store.getters.getEventCampaignsIds(campaign.id) "></a-checkbox>
                      </label>
                    </div>
                    <div class="md:w-3/12 sm:w-5/12 text-xs text-darkwhite-950 px-1 break-words pr-3">{{ campaign.title }}</div>
                    <div class="w-3/12 sm:hidden md:block text-xs text-darkwhite-950 px-1 break-words pr-3">
                      {{
                        (campaign.description.length > 100) ? campaign.description.substr(0, 100-1) + '&hellip;' : campaign.description
                      }}
                    </div>
                    <div class="w-2/12 sm:hidden md:block text-xs text-darkwhite-950 px-1 text-ellipsis overflow-hidden whitespace-nowrap">
                      {{ campaign.id }}
                    </div>
                    <div class="md:w-2/12 sm:w-5/12 text-xs text-darkwhite-950 px-1 text-ellipsis overflow-hidden whitespace-nowrap">
                      {{ campaign.code }}
                    </div>
                  </div>
                  <a-dropdown :trigger="['click']" class="tab-data-menu">
                    <a class="text-darkwhite-950 pl-2 absolute top-0 right-0" @click.prevent>
                      <MoreOutlined class="text-lg"/>
                    </a>
                    <template #overlay>
                      <a-menu>
<!--                        <a-menu-item key="0">-->
<!--                          <router-link to="/">{{$t('events.campaign-block.edit-campaign')}}</router-link>-->
<!--                        </a-menu-item>-->
                        <a-menu-item key="1">
                          <router-link to="#" @click="deleteCampaign(campaign.id)">{{$t('events.campaign-block.delete-campaign')}}</router-link>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              </div>
            </div>
            <a-pagination v-if="$store.state.allCampaignsPaging.total > 9" v-model:current="$store.state.allCampaignsPaging.page"
                          :total="$store.state.allCampaignsPaging.total"
                          :pageSize="$store.state.allCampaignsPaging.limit"
                          @change="getAllCampaigns"/>
          </a-tab-pane>
          <a-tab-pane key="2" v-bind:tab="$t('events.campaign-block.selected-campaigns')+'('+$store.state.eventCampaignsTotal+')'" force-render>
            <div class="px-4 py-2 pb-6 tab-content">
              <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
                <div class="w-1/12"></div>
                <div class="md:w-3/12 sm:w-5/12 text-xs px-1">{{$t('events.campaign-block.campaign-name')}}</div>
                <div class="w-3/12 sm:hidden md:block text-xs px-1">{{$t('events.campaign-block.campaign-detail')}}</div>
<!--                <div class="w-3/12 sm:hidden md:block text-xs px-1">{{ $t('events.campaign-block.campaign-id') }}</div>-->
                <div class="md:w-3/12 sm:w-5/12 text-xs px-1">{{$t('events.campaign-block.campaign-code')}}</div>
                <div class="w-1/12 text-xs px-1"></div>
              </div>
              <div class="w-full flex flex-col flex-wrap tab-content__data">
                <a-spin :spinning="this.$store.state.spinProduct">

                <div class="overflow-y-auto" style="height: 67vh;" >

                <div
                    class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                    v-for="eventCampaign in $store.state.eventCampaigns" :key="eventCampaign.id"
                    :value="eventCampaign.id"
                >

                  <div class="w-full flex flex-row justify-between items-center">
                    <div class="w-1/12">
                      <label class="text-xs text-gray-700 flex items-center cursor-pointer">
                        <a-checkbox v-if="this.$store.state.layout !=='streamer'" :value="eventCampaign.id" @change="setSelectedCampaigns" checked="checked"></a-checkbox>
                      </label>
                    </div>
                    <div class="md:w-3/12 sm:w-5/12 text-xs text-darkwhite-950 px-1 text-ellipsis overflow-hidden whitespace-nowrap break-words pr-3">
                      {{ eventCampaign.title }}
                    </div>
                    <div class="w-3/12 sm:hidden md:block text-xs text-darkwhite-950 px-1 break-words pr-3">

                      {{
                         typeof eventCampaign.description.length !== "undefined" && (eventCampaign.description.length > 100) ? eventCampaign.description.substr(0, 100-1) + '&hellip;' : eventCampaign.description
                      }}
                    </div>
<!--                    <div class="w-3/12 sm:hidden md:block text-xs text-darkwhite-950 px-1">{{ eventCampaign.id }}</div>-->
                    <div class="md:w-3/12 sm:w-5/12 text-xs text-darkwhite-950 px-1">{{ eventCampaign.code }}</div>
                    <!-- <div class="w-1/12 flex items-center justify-between">
                      <font-awesome-icon icon="play-circle" class="text-darkwhite-950 text-base"></font-awesome-icon>
                    </div> -->
                  </div>
                  </div>

                </div>
                </a-spin>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>

      </div>
    </div>
  </div>
  <CreateCampaign/>
</template>

<script>
import {MoreOutlined} from '@ant-design/icons-vue';
import CreateCampaign from "@/components/blocks/CreateCampaign";

const ALL_CAMPAIGNS = "1";
const EVENT_CAMPAIGNS = "2";
export default {
  components: {
    MoreOutlined, CreateCampaign
  },
  data() {
    return {
      campaignDrawer: false,

    }
  },
  computed: {
    activeCampaignTab: {
      get() {
        return this.$store.state.activeCampaignTab
      },
      set(val) {
        this.$store.commit('setActiveCampaignTab', val)
      }
    },
    selectedEvent: {
      get() {
        return this.$store.getters.getSelectedEvent
      }
    },
    eventCampaignsIds(){

      let eventCampaigns = this.$store.state.eventCampaigns
      let arr = []
      eventCampaigns.forEach((eventCampaign) =>{

        arr.push(eventCampaign.id)

      })

      return arr
    },

  },
  watch: {
    activeCampaignTab(val) {

      this.$store.commit('setActiveCampaignTab', val)

      switch (val) {

        case ALL_CAMPAIGNS:
          this.getAllCampaigns()
          break
        case EVENT_CAMPAIGNS:
          this.getEventCampaigns()
          break
      }

    },
    selectedEvent() {
      this.$store.commit('setActiveCampaignTab', EVENT_CAMPAIGNS)
      this.getEventCampaigns()
    }
  },
  methods: {
    deleteCampaign(id){

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed){
          this.$swal.showLoading()

          this.$appAxios.delete('/deleteCampaign/'+id,{
            headers: {
              'Authorization': [`Bearer ${localStorage.getItem("token")}`]
              // 'Content-Type': 'multipart/form-data'
            }
          }).then(() => {

            this.$store.dispatch('setAllCampaigns')
            this.$store.dispatch('setEventCampaigns')

            this.$swal({
              icon: "success",
              title: this.$t('events.campaign-block.process-success')
            })
          }).catch(error => {

                switch (error.response.status) {

                  case 500:
                    this.$swal({
                      icon: 'error',
                      title: this.$t('errors.system-error.title'),
                      html: this.$t('errors.system-error.text')
                    })
                    break

                  default:

                    this.$swal({
                      icon: 'error',
                      text: error.response.data.error.description
                    })
                    break
                }
              }
          )
        }
      })

    },
    selected(id){
      return this.eventCampaignsIds.includes(id)
    },
    getAllCampaigns() {
      this.$swal.showLoading()
      this.$store.dispatch('setAllCampaigns')
      this.$swal.close()
    },
    getEventCampaigns() {

      if (this.selectedEvent === false) {
        this.$store.commit('setEventCampaigns', [])
        this.$store.commit('setEventCampaignsTotal', 0)
        return
      }

      this.$swal.showLoading()
      this.$store.dispatch('setEventCampaigns')
      this.$swal.close()
    },
    setSelectedCampaigns(val) {

      if (this.selectedEvent === false){
        return this.$swal({
          icon: 'warning',
          text: this.$t("events.campaign-block.select-event-warning")
        })
      }

      this.$swal.showLoading()

      if (val.target.checked === true) {
        val.target.checked = true

        this.$appAxios.post('/addCampaignToEvent', {
          eventId: this.selectedEvent.id,
          campaignId: val.target.value
        }, {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then(() => {

          this.$store.dispatch('setEventCampaigns')
          this.$swal.close()

        }).catch(error => {

              switch (error.response.status) {

                case 500:
                  this.$swal({
                    icon: 'error',
                    title: this.$t('errors.system-error.title'),
                    html: this.$t('errors.system-error.text')
                  })
                  break

                case 422:

                  this.$swal({
                    icon: 'error',
                    text: this.$t('errors.validation-error.title')
                  })
                  // eslint-disable-next-line no-case-declarations
                  let obj = JSON.parse(error.response.data.error.description);

                  for (const [key, value] of Object.entries(obj)) {

                    this.errors[key].status = true
                    this.errors[key].message = value

                  }
                  break

                default:

                  this.$swal({
                    icon: 'error',
                    text: error.response.data.error.description
                  })
                  break
              }
            }
        )
      }
      else{
        this.$appAxios.post('/deleteCampaignFromEvent', {
          eventId: this.selectedEvent.id,
          campaignId: val.target.value
        }, {
          headers: {
            Authorization: [`Bearer ${localStorage.getItem("token")}`]
          }
        }).then(() => {

          this.$store.dispatch('setEventCampaigns')
          this.$swal.close()

        }).catch(error => {

              switch (error.response.status) {

                case 500:
                  this.$swal({
                    icon: 'error',
                    title: this.$t('errors.system-error.title'),
                    html: this.$t('errors.system-error.text')
                  })
                  break

                case 422:

                  this.$swal({
                    icon: 'error',
                    text: this.$t('errors.validation-error.title')
                  })
                  // eslint-disable-next-line no-case-declarations
                  let obj = JSON.parse(error.response.data.error.description);

                  for (const [key, value] of Object.entries(obj)) {

                    this.errors[key].status = true
                    this.errors[key].message = value

                  }
                  break

                default:

                  this.$swal({
                    icon: 'error',
                    text: error.response.data.error.description
                  })
                  break
              }
            }
        )
      }
    }
  },
  created() {
    this.getAllCampaigns()
    this.getEventCampaigns()
    this.$store.commit('setActiveCampaignTab', EVENT_CAMPAIGNS)

  }
};
</script>