  <template>
    <div class="card  md:w-6/12 sm:w-full" :class="this.$store.state.layout !=='streamer' ? 'lg:w-3/12':'lg:w-4/12'" >
      <div class="h-full bg-gray-200 rounded-md">
        <div class="card-top px-4 py-4 mb-2 flex justify-between items-center">
          <div class="card-title flex items-center">
            <span class="anticon bg-blue-100 w-10 h-10 rounded-3xl">
                <font-awesome-icon icon="shopping-basket" class="text-blue-500 text-base h-10"/>
            </span>
            <span class="text-lg font-medium ml-3">{{ $t('events.product-block.products') }}</span>
          </div>
          <div class="card-link">
            <a-button
                v-if="this.$store.state.layout !=='streamer'"
                @click="showDrawer"
                type="primary"
                class="bg-blue-500 font-normal text-xs text-white hover:text-white px-5 py-1 h-8 rounded-md"
                :disabled="Object.keys($store.state.selectedEvent).length === 0"
            >
              {{ $t('events.product-block.all-products') }}
            </a-button>
          </div>
        </div>
        <div class="card-center card-tabs">
          <a-tabs v-model:activeKey="activeKeyProducts">
            <a-tab-pane key="1" :tab="$t('events.product-block.selected-products')+'('+$store.state.eventProductsTotal+')'">
              <div class="px-4 py-2 pb-6 tab-content">
                <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
                  <div class="w-2/12"></div>
                  <div class="w-4/12 text-xs font-medium">{{ $t('events.product-block.product-name') }}</div>
                  <div class="w-2/12 text-xs font-medium">{{ $t('events.product-block.product-price') }}</div>
                  <div class="w-2/12 text-xs font-medium"></div>
                </div>

                <div class="w-full  flex flex-col flex-wrap tab-content__data">
                  <a-spin :spinning="this.$store.state.spinProduct">

                  <div class="overflow-y-auto" style="height: 67vh;" >
                    <div
                        class="w-full flex flex-row  flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                        v-for="product in $store.state.eventProducts" :key="product.id"
                        :value="product.id"
                    >
                      <div class="w-full flex flex-row justify-between items-center">
                        <div class="w-2/12">
                          <img :src="product.image_urls" style="max-height: 70px;" alt="Product Image">
                        </div>
                        <div class="w-4/12 text-xs text-darkwhite-950 px-1">
                          {{ product.title }}
                        </div>
                        <div class="w-2/12 text-xs text-darkwhite-950 px-1">{{ product.price + ' TL' }}</div>
                        <div class="w-2/12 flex items-center justify-center">
                          <font-awesome-icon @click="removeProductFromEvent(product.product_id)" icon="trash"
                                             v-if="this.$store.state.layout !=='streamer'"
                                             class="text-red-600 text-base" id="removeProduct"></font-awesome-icon>
                        </div>

                      </div>
                    </div>
                  </div>
                </a-spin>

                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    <AllProducts/>
  </template>

  <script>
  // import {MoreOutlined} from '@ant-design/icons-vue';
  import AllProducts from "@/components/blocks/AllProducts";

  export default {
    components: {
      // MoreOutlined,
      AllProducts
    },
    data() {
      return {
        activeKeyProducts: "1",
        productDrawer: false,
        filterDrawer: false
      }
    },
    computed:{
      selectedEvent(){
        return this.$store.getters.getSelectedEvent
      }
    },
    watch:{
      selectedEvent(val){
        if (val !== false){
          this.$store.dispatch('setEventProducts')
        }
      }
    },
    methods: {
      showDrawer() {
        this.$store.commit('setAllProductsPaging',{
          hasNextPage:false,
          hasPreviousPage:false,
          limit:this.$store.state.allProductsPaging.limit,
          startCursor: null,
          endCursor: null,
          page:1,
          total:this.$store.state.allProductsPaging.total
        })
        this.$store.commit('setAllProductsDrawer', true);
      },
      removeProductFromEvent(id) {

          this.$store.dispatch('removeProductFromEvent', id)
      },

    },
    created() {
      if (this.$store.getters.getSelectedEvent !== false) {
        this.$store.dispatch('setEventProducts')
      }else{
        this.$store.commit("setEventProducts",[])
        this.$store.commit('setEventProductsTotal',0)
      }
    }
  };
  </script>
  <style>
  #removeProduct {
    cursor: pointer;
  }
  </style>