  <template>

    <div class="card lg:w-3/12 md:w-6/12 sm:w-full">
      <div class="h-full bg-gray-200 rounded-md">
        <div class="card-top px-4 py-4 mb-2 flex justify-between items-center">
          <div class="card-title flex items-center">
                      <span class="anticon bg-blue-100 w-10 h-10 rounded-3xl">
                          <font-awesome-icon icon="percentage" class="text-blue-500 text-base h-10"/>
                      </span>
            <span class="text-lg font-medium ml-3">{{ $t('events.influencer-block.influencers') }}</span>
          </div>
          <div class="card-link">
            <a-button
                @click="$store.commit('setInfluencerDrawer',true)"
                type="primary"
                class="bg-blue-500 font-normal text-xs text-white hover:text-white px-5 py-1 h-8 rounded-md"
            >
              + {{ $t('events.influencer-block.new-influencer') }}
            </a-button>
          </div>
        </div>
        <div class="card-center card-tabs">
          <a-tabs v-model:activeKey="activeKeyInfluencer">
            <a-tab-pane key="1" :tab="$t('events.influencer-block.all-influencers')+'('+$store.state.allInfluencersPaging.total+')'">
              <div class="px-4 py-2 pb-6 tab-content">
                <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
                  <div class="w-2/12"></div>
                  <div class="w-3/12 text-xs"></div>
                  <div class="w-7/12 text-xs font-medium">{{ $t('events.influencer-block.influencer-name')}}</div>
                </div>
                <div class="w-full flex flex-col flex-wrap tab-content__data">

                  <div
                      class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                      v-if="selectedInfluencer"
                  >
                    <div class="w-full flex flex-row justify-between items-center" >
                      <div class="w-2/12 text-xs text-darkwhite-950 px-1">
                        {{ $t('events.influencer-block.selected-influencer') }}:
                      </div>
                      <div class="w-3/12">
                        <a-avatar size="large" :src="selectedInfluencer.image_url !== null ? selectedInfluencer.image_url : require('../../assets/img/influencer.png')" alt="Influencer Image" v-if="selectedInfluencer !== false"/>
                      </div>
                      <div class="w-7/12 text-xs text-darkwhite-950 px-1" v-if="selectedInfluencer !== false">
                        {{selectedInfluencer.name+" "+ selectedInfluencer.surname}}
                      </div>
                    </div>
                  </div>
                  <div
                      class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                      v-for="influencer in $store.state.allInfluencers" :key="influencer.id" :value="influencer.id">
                    <div class="w-full flex flex-row justify-between items-center">
                      <div class="w-2/12 text-xs text-darkwhite-950 px-1">
                        <label class="text-xs text-gray-700 flex items-center cursor-pointer">
                          <a-checkbox :value="influencer" name="type"
                                      :checked="typeof selectedInfluencer !== 'undefined' && selectedInfluencer.id === influencer.id" @change="setEventInfluencer"></a-checkbox>
                        </label>
                      </div>
                      <div class="w-3/12">
                        <a-avatar size="large" :src="influencer.image_url !== null ? influencer.image_url : require('../../assets/img/influencer.png')" alt="Influencer Image"/>
                      </div>
                      <div class="w-7/12 text-xs text-darkwhite-950 px-1">
                        {{ influencer.name + " " + influencer.surname }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a-pagination v-if="$store.state.allInfluencers>5" v-model:current="$store.state.allInfluencersPaging.page"
                            :total="$store.state.allInfluencersPaging.total"
                            :pageSize="$store.state.allInfluencersPaging.limit"
                            @change="getAllInfluencers"/>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>

    </div>
    <CreateInfluencer/>

  </template>

  <script>
  import CreateInfluencer from "@/components/blocks/CreateInfluencer";

  export default {
    components: {
      CreateInfluencer
    },
    data() {
      return {
        activeKeyInfluencer: "1",
        url:process.env.VUE_APP_URL
      }
    },
    methods: {
      goLive(){
        if (this.selectedEvent.id !== undefined){
          window.location.href ='/user/live/'+ this.selectedEvent.id;
        }else {
          this.$swal({
            icon:"warning",
            text:this.$t('events.influencer-block.select-event-for-live')
          })
        }
      },
      getAllInfluencers() {
        this.$store.dispatch('setAllInfluencers')
      },
      setEventInfluencer(val){
        if (this.selectedEvent === false){
          return this.$swal({
            icon: 'warning',
            text: this.$t('events.influencer-block.select-event-warning')
          })

        }
        this.$swal.showLoading()
        if (val.target.checked === true){

          this.$appAxios.post('/addStreamerToEvent', {
            eventId : this.$store.state.selectedEvent.id,
            streamerId: Number(val.target.value.id)
          },{
            headers: {
              Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },
          }).then(() => {

            this.$store.state.selectedEvent.streamer = val.target.value
            this.$swal.close()
          }).catch(error => {

                switch (error.response.status) {

                  case 500:
                    this.$swal({
                      icon: 'error',
                      title: this.$t('errors.system-error.title'),
                      html: this.$t('errors.system-error.text')
                    })
                    break

                  case 422:

                    this.$swal({
                      icon: 'error',
                      text: this.$t('errors.validation-error.title')
                    })
                    // eslint-disable-next-line no-case-declarations
                    let obj = JSON.parse(error.response.data.error.description);

                    for (const [key, value] of Object.entries(obj)) {

                      this.errors[key].status = true
                      this.errors[key].message = value

                    }
                    break

                  default:

                    this.$swal({
                      icon: 'error',
                      text: error.response.data.error.description
                    })
                    break
                }
              }
          )
        }else{

          this.$appAxios.post('/clearEventStreamer', {
            eventId : this.$store.state.selectedEvent.id,
          },{
            headers: {
              Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },
          }).then(() => {

            this.$store.state.selectedEvent.streamer = null
            this.$swal.close()
          }).catch(error => {

                switch (error.response.status) {

                  case 500:
                    this.$swal({
                      icon: 'error',
                      title: this.$t('errors.system-error.title'),
                      html: this.$t('errors.system-error.text')
                    })
                    break

                  case 422:

                    this.$swal({
                      icon: 'error',
                      text: this.$t('errors.validation-error.title')
                    })
                    // eslint-disable-next-line no-case-declarations
                    let obj = JSON.parse(error.response.data.error.description);

                    for (const [key, value] of Object.entries(obj)) {

                      this.errors[key].status = true
                      this.errors[key].message = value

                    }
                    break

                  default:

                    this.$swal({
                      icon: 'error',
                      text: error.response.data.error.description
                    })
                    break
                }
              }
          )

        }
      }
    },
    computed: {
      selectedEvent: {
        get() {
          return this.$store.getters.getSelectedEvent
        }
      },
      selectedInfluencer() {
        if (this.selectedEvent !== false) {

          if(this.selectedEvent.streamer !== null) {
            return this.selectedEvent.streamer
          }
          return false
        }
        return false
      }
    },

    created() {
      this.$store.dispatch('setAllInfluencers')

    }
  };
  </script>

  <style>

  </style>