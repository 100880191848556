  <template>
    <a-drawer
        v-model:visible="allProductsDrawer"
        @close="allProductsDrawer = false"
        :footer-style="{ textAlign: 'right' }"
        class="products-drawer drawer-form"
        :title="$t('events.create-products-drawer.products')"
        placement="right"
        width="400px"
    >
      <div class="w-full flex flex-wrap items-center justify-between mb-5">
<!--        <div class="w-full flex items-center justify-between mb-5">-->
<!--          <span>{{ $t('events.create-products-drawer.products') }}</span>-->
<!--          &lt;!&ndash;                <div class="card-link">&ndash;&gt;-->
<!--          &lt;!&ndash;                    <a-button &ndash;&gt;-->
<!--          &lt;!&ndash;                        @click="showFilterDrawer" &ndash;&gt;-->
<!--          &lt;!&ndash;                        type="primary" &ndash;&gt;-->
<!--          &lt;!&ndash;                        class="bg-blue-500 font-normal text-xs text-white hover:text-white px-5 py-1 h-8 rounded-md"&ndash;&gt;-->
<!--          &lt;!&ndash;                    >&ndash;&gt;-->
<!--          &lt;!&ndash;                        Filtrele&ndash;&gt;-->
<!--          &lt;!&ndash;                    </a-button>&ndash;&gt;-->
<!--          &lt;!&ndash;                </div>&ndash;&gt;-->
<!--        </div>-->
        <a-input-search v-model:value="searchValue" :placeholder="$t('events.create-products-drawer.all-products')"  :loading="searchBarLoader" />

      </div>
      <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
        <div class="w-1/12"></div>
        <div class="w-2/12"></div>
        <div class="w-4/12 text-xs font-medium">{{$t('events.create-products-drawer.product-name')}}</div>
        <div class="w-2/12 text-xs font-medium">{{$t('events.create-products-drawer.stock')}}</div>
        <div class="w-2/12 text-xs font-medium">{{$t('events.create-products-drawer.price')}}</div>
      </div>
      <div class="w-full flex flex-col flex-wrap">
        <div
            class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
            v-for="product in $store.state.allProducts" :key="product" :value="product">
          <div class="w-full flex flex-row justify-between items-center">
            <div class="w-1/12 text-xs text-darkwhite-950 px-1">
              <label class="text-xs text-gray-700 flex items-center cursor-pointer">
                <a-checkbox :value="product" @change="addProductToEvent" :checked="$store.getters.getEventProductsIds(product.productId)" ></a-checkbox>
              </label>
            </div>
            <div class="w-2/12">
              <img :src="product.images" style="max-height: 70px;" :alt="product.url">
            </div>
            <div class="w-4/12 text-xs text-darkwhite-950 px-1">
              {{ product.title }}
            </div>
            <div class="w-2/12 text-xs text-darkwhite-950 px-1">{{ product.stock }}</div>
            <div class="w-2/12 text-xs text-darkwhite-950 px-1">{{ product.price.toFixed(2) + ' ' +product.listPriceCurrency }}</div>
          </div>
<!--          <a-dropdown :trigger="['click']" class="tab-data-menu">-->
<!--            <a class="text-darkwhite-950 pl-2 absolute top-0 right-0" @click.prevent>-->
<!--              <MoreOutlined class="text-lg"/>-->
<!--            </a>-->
<!--            <template #overlay>-->
<!--              <a-menu>-->
<!--                <a-menu-item key="0">-->
<!--                  <router-link to="/">Düzenle</router-link>-->
<!--                </a-menu-item>-->
<!--                <a-menu-item key="1">-->
<!--                  <router-link to="#">Sil</router-link>-->
<!--                </a-menu-item>-->
<!--              </a-menu>-->
<!--            </template>-->
<!--          </a-dropdown>-->
        </div>
        <!--      <div class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data&#45;&#45;item">-->
        <!--        <div class="w-full flex flex-row justify-between items-center">-->
        <!--          <div class="w-1/12 text-xs text-darkwhite-950 px-1">-->
        <!--            <label for="rememberMe" class="text-xs text-gray-700 flex items-center cursor-pointer">-->
        <!--              <a-checkbox value="1" name="type"></a-checkbox>-->
        <!--            </label>-->
        <!--          </div>-->
        <!--          <div class="w-2/12">-->
        <!--            <img src="@/assets/img/proImg.png" alt="Product Image">-->
        <!--          </div>-->
        <!--          <div class="w-4/12 text-xs text-darkwhite-950 px-1">-->
        <!--            Shop LC 925 Sterling Silver Earrings Stylish Unique Fash...-->
        <!--          </div>-->
        <!--          <div class="w-2/12 text-xs text-darkwhite-950 px-1">1881</div>-->
        <!--          <div class="w-2/12 text-xs text-darkwhite-950 px-1">1938 TL</div>-->
        <!--        </div>-->
        <!--        <a-dropdown :trigger="['click']" class="tab-data-menu">-->
        <!--          <a class="text-darkwhite-950 pl-2 absolute top-0 right-0" @click.prevent>-->
        <!--            <MoreOutlined class="text-lg" />-->
        <!--          </a>-->
        <!--          <template #overlay>-->
        <!--            <a-menu>-->
        <!--              <a-menu-item key="0">-->
        <!--                <router-link to="/">Düzenle</router-link>-->
        <!--              </a-menu-item>-->
        <!--              <a-menu-item key="1">-->
        <!--                <router-link to="#">Sil</router-link>-->
        <!--              </a-menu-item>-->
        <!--            </a-menu>-->
        <!--          </template>-->
        <!--        </a-dropdown>-->
        <!--      </div>-->
      </div>

      <!--        <a-drawer-->
      <!--            v-model:visible="filterDrawer"-->
      <!--            @close="onCloseFilter"-->
      <!--            title="Ürünler Filtre"-->
      <!--            width="400px"-->
      <!--        >-->
      <!--            <a-form ref="modalFormRef" layout="vertical" name="eventForm">-->
      <!--                <a-form-item-->
      <!--                    name="proGender"-->
      <!--                    label="Cinsiyet"-->
      <!--                    has-feedback-->
      <!--                >-->
      <!--                    <a-select class="w-full" placeholder="Lütfen Cinsiyet seçiniz">-->
      <!--                        <a-select-option value="TSoft">T-Soft</a-select-option>-->
      <!--                        <a-select-option value="FSoft">F-Soft</a-select-option>-->
      <!--                    </a-select>-->
      <!--                </a-form-item>-->
      <!--                <a-form-item-->
      <!--                    name="proSize"-->
      <!--                    label="Beden"-->
      <!--                    has-feedback-->
      <!--                >-->
      <!--                    <a-select class="w-full" placeholder="Lütfen Beden seçiniz">-->
      <!--                        <a-select-option value="TSoft">T-Soft</a-select-option>-->
      <!--                        <a-select-option value="FSoft">F-Soft</a-select-option>-->
      <!--                    </a-select>-->
      <!--                </a-form-item>-->
      <!--                <a-form-item-->
      <!--                    name="proCat"-->
      <!--                    label="Kategori"-->
      <!--                    has-feedback-->
      <!--                >-->
      <!--                    <a-select class="w-full" placeholder="Lütfen Kategori seçiniz">-->
      <!--                        <a-select-option value="TSoft">T-Soft</a-select-option>-->
      <!--                        <a-select-option value="FSoft">F-Soft</a-select-option>-->
      <!--                    </a-select>-->
      <!--                </a-form-item>-->
      <!--                <a-form-item-->
      <!--                    name="proBrand"-->
      <!--                    label="Marka"-->
      <!--                    has-feedback-->
      <!--                >-->
      <!--                    <a-select class="w-full" placeholder="Lütfen Marka seçiniz">-->
      <!--                        <a-select-option value="TSoft">T-Soft</a-select-option>-->
      <!--                        <a-select-option value="FSoft">F-Soft</a-select-option>-->
      <!--                    </a-select>-->
      <!--                </a-form-item>-->
      <!--                <a-form-item-->
      <!--                    name="eventPlatform"-->
      <!--                    label="Renk"-->
      <!--                    has-feedback-->
      <!--                >-->
      <!--                    <a-select-->
      <!--                        mode="multiple"-->
      <!--                        style="width: 100%"-->
      <!--                        placeholder="Please select"-->
      <!--                        :options="[...Array(25)].map((_, i) => ({ value: (i + 10).toString(36) + (i + 1) }))"-->
      <!--                        @change="changeColor"-->
      <!--                    ></a-select>-->
      <!--                </a-form-item>-->
      <!--            </a-form>-->
      <!--        </a-drawer>-->
      <a-pagination v-if="$store.state.allProductsPaging.total > 9 && Object.keys($store.state.selectedEvent).length !== 0 "
                    v-model:current="$store.state.allProductsPaging.page"
                    :total="$store.state.allProductsPaging.total"
                    :pageSize="$store.state.allProductsPaging.limit"
                    @change="getAllProducts"
      />
      <div v-else>
        <font-awesome-icon v-if="$store.state.allProductsPaging.hasPreviousPage" icon="arrow-left" @click="shopifyPagination(0)"/>
        <font-awesome-icon v-if="$store.state.allProductsPaging.hasNextPage" icon="arrow-right" @click="shopifyPagination(1)"/>
      </div>
    </a-drawer>
  </template>

  <script>
  // import {MoreOutlined} from "@ant-design/icons-vue";
  import {appAxios} from "@/utils/appAxios";


  export default {
    components: {
      // MoreOutlined
    },
    data() {
      return {
        searchValue:"",
        searchTimeOut : null,
        searchBarLoader:false
      }
    },
    methods: {
      shopifyPagination(direction){
        switch (direction){
          case 0:
            this.$store.commit('setAllProductsPaging',{
              hasNextPage:this.$store.state.allProductsPaging.hasNextPage,
              hasPreviousPage:this.$store.state.allProductsPaging.hasPreviousPage,
              limit:this.$store.state.allProductsPaging.limit,
              startCursor: this.$store.state.allProductsPaging.startCursor,
              endCursor: null ,
              page:this.$store.state.allProductsPaging.page,
              total:this.$store.state.allProductsPaging.total
            })
                break;
          case 1:
            this.$store.commit('setAllProductsPaging',{
              hasNextPage:this.$store.state.allProductsPaging.hasNextPage,
              hasPreviousPage:this.$store.state.allProductsPaging.hasPreviousPage,
              limit:this.$store.state.allProductsPaging.limit,
              startCursor: null,
              endCursor: this.$store.state.allProductsPaging.endCursor,
              page:this.$store.state.allProductsPaging.page,
              total:this.$store.state.allProductsPaging.total
            })
            break
          default:
            console.log('gecersiz pagination direction')
        }
        this.getAllProducts()
      },
      getAllProducts() {

        this.$swal.showLoading()
        this.$store.dispatch('setAllProducts',this.searchValue).then(() => {
                  this.$swal.close()
                }
            )
      },
      addProductToEvent(val){
        let product = val.target.value
        if (val.target.checked === true){
          console.log('------------------------------')
          console.log(this.$store.state.selectedEvent);
          console.log('------------------------------')
          let platformId = 0;
          if (typeof  this.$store.state.selectedEvent.platform === "undefined"){
            platformId = this.$store.state.selectedEvent.platform_id;

          }else {
            platformId = this.$store.state.selectedEvent.platform.id
          }

          appAxios.post('/addProductToEvent', {
            productId:product.productId,
            eventId:this.$store.state.selectedEvent.id,
            title:product.title,
            description:product.description,
            price:product.price.toFixed(2),
            discountPrice:product.discountPrice,
            image:product.images,
            url:product.url,
            platformId:platformId,
            currency:product.listPriceCurrency,
            discount_currency:product.discountPriceCurrency
          },{
            headers: {
              Authorization: [`Bearer ${localStorage.getItem("token")}`]
            }
          }).then(() => {
            this.$store.dispatch('setEventProducts')
            // commit('setAllProductsPaging', {total:response.data.data.total,page:response.data.data.page,limit:response.data.data.limit})
          }).catch(error => {

                switch (error.response.status) {

                  case 500:
                    this.$swal({
                      icon: 'error',
                      title: this.$t('errors.system-error.title'),
                      html: this.$t('errors.system-error.text')
                    })
                    break

                  case 422:

                    this.$swal({
                      icon: 'error',
                      text: this.$t('errors.validation-error.title')
                    })
                    // eslint-disable-next-line no-case-declarations
                    let obj = JSON.parse(error.response.data.error.description);

                    for (const [key, value] of Object.entries(obj)) {

                      this.errors[key].status = true
                      this.errors[key].message = value

                    }
                    break

                  default:

                    this.$swal({
                      icon: 'error',
                      text: error.response.data.error.description
                    })
                    break
                }
              }
          )
        }else{
          this.$store.dispatch('removeProductFromEvent', product.productId)
        }
      }
    },
    computed: {
      allProductsDrawer: {
        get() {
          return this.$store.state.allProductsDrawer
        },
        set(val) {
          this.$store.commit('setAllProductsDrawer', val)
        }
      }
    },
    watch: {
      allProductsDrawer(val) {
        if (val === true) {
          this.getAllProducts()
        }
      },
      searchValue(val){
        if (this.searchTimeOut !== null){
          this.searchBarLoader = false
          clearTimeout(this.searchTimeOut)
        }

        this.$store.commit('setAllProductsPaging',{hasNextPage:false,hasPreviousPage:false,limit:this.$store.state.allProductsPaging.limit,startCursor: null,endCursor: null,page:1,total:this.$store.state.allProductsPaging.total})

        this.searchTimeOut = setTimeout(()=> {
          this.searchBarLoader = true
          this.$store.dispatch('setAllProducts', val)
        },1000)
      }
    },
    created() {
      this.$store.commit('setAllProductsPaging',{
        hasNextPage:this.$store.state.allProductsPaging.hasNextPage,
        hasPreviousPage:this.$store.state.allProductsPaging.hasPreviousPage,
        limit:this.$store.state.allProductsPaging.limit,
        startCursor: null,
        endCursor: null,
        page:this.$store.state.allProductsPaging.page,
        total:this.$store.state.allProductsPaging.total
      })
    }
  }
  </script>

  <style>

  </style>