<template>
  <a-drawer v-model:visible="$store.state.influencerDrawer" @close="$store.commit('setInfluencerDrawer', false)"
    :footer-style="{ textAlign: 'right' }" class="influencer-drawer drawer-form"
    :title="$t('events.create-influencer-drawer.new-influencer')" placement="right" width="400px">
    <a-form ref="modalFormRef" layout="vertical" name="infForm">
      <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
        :before-upload="beforeUpload">
        <img v-if="createInfluencer.image !== null" :src="createInfluencer.image.thumbUrl" alt="avatar"
          class="text-center" />
        <div v-else>
          <!--          <loading-outlined v-if="loading"></loading-outlined>-->
          <!--          <plus-outlined ></plus-outlined>-->
          <div class="ant-upload-text"> {{ $t('events.create-influencer-drawer.upload-image') }} </div>
        </div>
      </a-upload>


      <a-form-item name="infName" :label="$t('events.create-influencer-drawer.influencer-name')">
        <a-input v-model:value="createInfluencer.name" />
        <a-alert v-if="errors.name.status" type="error" v-bind:message="errors.name.message" banner />
      </a-form-item>

      <a-form-item name="infSurname" :label="$t('events.create-influencer-drawer.influencer-surname')">
        <a-input v-model:value="createInfluencer.surname" />
        <a-alert v-if="errors.surname.status" type="error" v-bind:message="errors.surname.message" banner />
      </a-form-item>

      <a-form-item name="infPosta" :label="$t('events.create-influencer-drawer.influencer-email')">
        <a-input v-model:value="createInfluencer.email" type="email" />
        <a-alert v-if="errors.email.status" type="error" v-bind:message="errors.email.message" banner />
      </a-form-item>

    </a-form>
    <button
      class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
      v-on:click="saveInfluencer">
      {{ $t('events.create-influencer-drawer.invite-influencer') }}
    </button>
  </a-drawer>
</template>

<script>
// import {LoadingOutlined, PlusOutlined} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export default {
  // components: {
  //   PlusOutlined, LoadingOutlined
  // },
  data() {
    return {

      createInfluencer: {
        image: null,
        name: null,
        surname: null,
        email: null
      },
      errors: {
        name: {
          status: false,
          message: ""
        },
        surname: {
          status: false,
          message: ""
        },
        email: {
          status: false,
          message: ""
        }
      }
    }
  },
  methods: {

    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'png';

      if (!isJpgOrPng) {
        message.error('Lütfen fotoğraf yükleyiniz !');
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        file.thumbUrl = e.target.result;
        this.createInfluencer.image = file
      }
      const isLt8M = file.size / 1024 / 1024 < 8;
      if (!isLt8M) {
        this.$swal({
          icon: 'error',
          title: this.$t('events.create-event-drawer.image-file-size-error'),
        }).then(() => {
          this.createInfluencer.image = null;
        })

      }
      return false;
    },
    saveInfluencer() {

      let formData = new FormData()

      for (let property in this.createInfluencer) {

        if (this.createInfluencer[property] == null)
          continue

        formData.append(property, this.createInfluencer[property])
      }

      this.$swal.showLoading()
      this.$appAxios.post('/createStreamer', formData, {
        headers: {
          'Authorization': [`Bearer ${localStorage.getItem("token")}`],
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {

        this.$store.commit('setInfluencerDrawer', false)
        this.createInfluencer = {
          name: null,
          surname: null,
          email: null,
          image: null
        }
        this.$store.dispatch('setAllInfluencers')

        this.$swal({
          icon: "success",
          title: this.$t('events.create-influencer-drawer.process-success')
        })
      }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          case 422:

            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            // eslint-disable-next-line no-case-declarations
            let obj = JSON.parse(error.response.data.error.description);

            for (const [key, value] of Object.entries(obj)) {

              this.errors[key].status = true
              this.errors[key].message = value

            }
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
  },
  computed: {
    influencerDrawer: {
      get() {
        return this.$store.state.eventDrawer
      },
      set(val) {
        this.$store.commit('setEventDrawer', val)
      }
    }
  },

}
</script>

<style>
.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>