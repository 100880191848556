import {appAxios} from "@/utils/appAxios";

const Providers = {

    getProviders(){

        return appAxios.get('/providers', {
            headers: {
                Authorization: [`Bearer ${localStorage.getItem("token")}`]
            },

        })
    },


}

export default Providers