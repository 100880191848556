<template>
  <a-drawer v-model:visible="$store.state.campaignDrawer" @close="this.$store.commit('setCampaignDrawer', false)"
    :footer-style="{ textAlign: 'right' }" class="campaign-drawer drawer-form"
    :title="$t('events.create-campaign-drawer.create-campaign')" placement="right" width="400px">
    <a-form ref="modalFormRef" layout="vertical" name="campForm">

      <a-upload list-type="picture-card" name="campaign-image" :show-upload-list="false" class="avatar-uploader"
        :before-upload="preventUpload">
        <img v-if="createCampaign.image !== null" :src="createCampaign.image.thumbUrl" alt="campaign-image"
          class="text-center" />
        <div v-else>
          <!--          <a-icon type="plus" />-->
          <div class="ant-upload-text"> {{ $t('events.create-campaign-drawer.upload-image') }} </div>

        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>

      <a-form-item name="campName" :label="$t('events.create-campaign-drawer.campaign-name')">
        <a-input v-model:value="createCampaign.title" />
        <a-alert v-if="errors.title.status" type="error" v-bind:message="errors.title.message" banner />
      </a-form-item>

      <a-form-item name="campDetail" :label="$t('events.create-campaign-drawer.campaign-description')">
        <a-textarea v-model:value="createCampaign.description" />
        <a-alert v-if="errors.description.status" type="error" v-bind:message="errors.description.message" banner />
      </a-form-item>

      <!--      <a-form-item name="campId" label="Kampanya ID">-->
      <!--        <a-input value="812548632587" />-->
      <!--      </a-form-item>-->

      <a-form-item name="campCode" :label="$t('events.create-campaign-drawer.campaign-code')">
        <a-input v-model:value="createCampaign.code" />
      </a-form-item>
    </a-form>
    <button
      class="w-full h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors"
      v-on:click="saveCampaign">
      {{ $t('events.create-campaign-drawer.save-campaign') }}
    </button>
  </a-drawer>
</template>

<script>

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);

  });
}

export default {

  data() {
    return {

      createCampaign: {
        title: null,
        description: null,
        code: null,
        image: null
      },
      errors: {
        title: {
          status: false,
          message: ""
        },
        description: {
          status: false,
          message: ""
        },
        code: {
          status: false,
          message: ""
        }
      }
    }
  },
  methods: {

    preventUpload(file) {

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        file.thumbUrl = e.target.result;
        this.createCampaign.image = file
      }
      const isLt8M = file.size / 1024 / 1024 < 8;
      if (!isLt8M) {
        this.$swal({
          icon: 'error',
          title: this.$t('events.create-event-drawer.image-file-size-error'),
        }).then(() => {
          this.createCampaign.image = null;
        })

      }
      return false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(fileList) {
      this.createCampaign.image = fileList.fileList
    },
    saveCampaign() {

      let formData = new FormData()

      for (let property in this.createCampaign) {

        if (this.createCampaign[property] == null)
          continue

        if (Array.isArray(this.createCampaign[property])) {

          for (let image in this.createCampaign[property]) {
            formData.append(property + '[]', this.createCampaign[property][image].originFileObj)
          }
          continue
        }

        formData.append(property, this.createCampaign[property])

      }

      this.$swal.showLoading()
      this.$appAxios.post('/addCampaign', formData, {
        headers: {
          'Authorization': [`Bearer ${localStorage.getItem("token")}`],
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (this.$store.getters.getSelectedEvent == true) { //this.$store.getters.getSelectedEvent !== false
          this.$appAxios.post('/addCampaignToEvent', {
            eventId: (this.$store.getters.getSelectedEvent).id,
            campaignId: res.data.data.campaign.id
          }, {
            headers: {
              Authorization: [`Bearer ${localStorage.getItem("token")}`]
            }
          }).then(() => {
            this.$store.dispatch('setEventCampaigns')
            this.$store.commit('setActiveCampaignTab', "1")
            this.$store.commit('setCampaignDrawer', false)
            this.$store.dispatch('setAllCampaigns')
            this.$swal({
              icon: "success",
              title: this.$t('events.create-campaign-drawer.campaign-created')
            })
          }).catch(error => {
            switch (error.response.status) {
              case 500:
                this.$swal({
                  icon: 'error',
                  title: this.$t('errors.system-error.title'),
                  html: this.$t('errors.system-error.text')
                })
                break
              default:
                this.$swal({
                  icon: 'error',
                  text: error.response.data.error.description
                })
                break
            }
          }
          );
        }

      }).then(() => {
            this.$store.commit('setActiveCampaignTab', "1")
            this.$store.commit('setCampaignDrawer', false)
            this.$store.dispatch('setAllCampaigns')
            this.$swal({
              icon: "success",
              title: this.$t('events.create-campaign-drawer.campaign-created')
            })
          }).catch(error => {

        switch (error.response.status) {

          case 500:
            this.$swal({
              icon: 'error',
              title: this.$t('errors.system-error.title'),
              html: this.$t('errors.system-error.text')
            })
            break

          case 422:

            this.$swal({
              icon: 'error',
              text: this.$t('errors.validation-error.title')
            })
            // eslint-disable-next-line no-case-declarations
            let obj = JSON.parse(error.response.data.error.description);

            for (const [key, value] of Object.entries(obj)) {

              this.errors[key].status = true
              this.errors[key].message = value

            }
            break

          default:

            this.$swal({
              icon: 'error',
              text: error.response.data.error.description
            })
            break
        }
      }
      )
    },
  },
  computed: {
    campaignDrawer: {
      get() {
        return this.$store.state.eventDrawer
      },
      set(val) {
        this.$store.commit('setEventDrawer', val)
      }
    }
  }

}
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>