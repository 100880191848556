  <template>
    <a-button
        @click="goLive"
        type="primary"
        class="w-auto fixed items-center bottom-3 right-0 ml-3 mr-3 bg-orange-500 hover:bg-red-500 border-orange-800 font-normal text-white hover:text-white rounded-md"
        style="z-index: 999;"
    >
      <font-awesome-icon icon="video" class=" text-base mr-2"/>
      {{ $t('events.influencer-block.go-live') }}
    </a-button>
    <Events/>
    <Products/>
    <Campaign/>
    <Influencer v-if="this.$store.state.layout !=='streamer'"/>
  </template>

  <script>
  import Events from '@/components/blocks/Event';
  import Products from '@/components/blocks/Products';
  import Campaign from '@/components/blocks/Campaign';
  import Influencer from '@/components/blocks/Influencer';

  export default {
    components: {
      Events, Products, Campaign, Influencer
    },
    data() {
      return {}
    },
    methods: {
        goLive(){
          if (this.$store.state.selectedEvent.id !== undefined){
            if (this.$store.state.layout !=='streamer'){
              window.location.href ='/user/live/'+ this.$store.state.selectedEvent.id;
            }else {
              window.location.href ='/streamer/live/'+ this.$store.state.selectedEvent.id;
            }
          }else {
            this.$swal({
              icon:"warning",
              text:this.$t('events.influencer-block.select-event-for-live')
            })
          }
        },
    },

  };
  </script>